exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-play-js": () => import("./../../../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-work-adaptive-learning-js": () => import("./../../../src/pages/work/adaptive-learning.js" /* webpackChunkName: "component---src-pages-work-adaptive-learning-js" */),
  "component---src-pages-work-dot-js": () => import("./../../../src/pages/work/dot.js" /* webpackChunkName: "component---src-pages-work-dot-js" */),
  "component---src-pages-work-innovation-dash-js": () => import("./../../../src/pages/work/innovation-dash.js" /* webpackChunkName: "component---src-pages-work-innovation-dash-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-krab-io-js": () => import("./../../../src/pages/work/krab-io.js" /* webpackChunkName: "component---src-pages-work-krab-io-js" */),
  "component---src-pages-work-moodie-js": () => import("./../../../src/pages/work/moodie.js" /* webpackChunkName: "component---src-pages-work-moodie-js" */),
  "component---src-pages-work-retail-systems-js": () => import("./../../../src/pages/work/retailSystems.js" /* webpackChunkName: "component---src-pages-work-retail-systems-js" */)
}

